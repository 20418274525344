import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import type { PayloadAction } from "@reduxjs/toolkit";
import { print } from "graphql/language/printer";
import gql from "graphql-tag";
import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { Pod } from "../../../../pod";
// Define a type for the slice state
interface AppState {
  init: boolean;
  framePod: any;
  config: {
    ai: {
      enabled: boolean;
    };
  };
}

const initialState: AppState = {
  init: false,
  framePod: undefined,
  config: {
    ai: {
      enabled: true,
    },
  },
};
export const TrainingCoursesCourseFrameSlice = createSlice({
  name: "TrainingCoursesCourseFrameSlice",
  initialState,
  reducers: {
    setInit: (state, action: PayloadAction<boolean>) => {
      state.init = action.payload;
    },
    setFramePod: (state, action: PayloadAction<Pod>) => {
      state.framePod = action.payload;
    },
    setIcon: (state, action: PayloadAction<string>) => {
      state.framePod.icon = action.payload;
    },
    setDesc: (state, action: PayloadAction<string>) => {
      state.framePod.desc = action.payload;
    },
  },
});

export const { setInit, setFramePod, setIcon, setDesc } =
  TrainingCoursesCourseFrameSlice.actions;

export default TrainingCoursesCourseFrameSlice.reducer;
