import {
  IonApp,
  IonRouterOutlet,
  IonSplitPane,
  setupIonicReact,
} from "@ionic/react";
import { IonReactRouter } from "@ionic/react-router";
import { Redirect, Route } from "react-router-dom";
import Menu from "./components/Menu";
import Page from "./pages/Page";

/* Core CSS required for Ionic components to work properly */
import "@ionic/react/css/core.css";

/* Basic CSS for apps built with Ionic */
import "@ionic/react/css/normalize.css";
import "@ionic/react/css/structure.css";
import "@ionic/react/css/typography.css";

/* Optional CSS utils that can be commented out */
import "@ionic/react/css/padding.css";
import "@ionic/react/css/float-elements.css";
import "@ionic/react/css/text-alignment.css";
import "@ionic/react/css/text-transformation.css";
import "@ionic/react/css/flex-utils.css";
import "@ionic/react/css/display.css";

/* Theme variables */
import "./theme/variables.css";
import Home from "./pages/Home";
import TrainingCourses from "./pages/training/TrainingCourses";
import TrainingCoursesCourse from "./pages/training/course/TrainingCoursesCourse";
import TrainingCoursesCourseFrame from "./pages/training/course/frame/TrainingCoursesCourseFrame";
import TrainingPlayer from "./pages/training/player/TrainingPlayer";
import TrainingPlayerCourse from "./pages/training/player/course/TrainingPlayerCourse";
import Documents from "./pages/documents/Documents";
import DocumentsSection from "./pages/documents/section/DocumentsSection";
import DocumentsSectionPages from "./pages/documents/section/pages/DocumentsSectionPages";
import DocumentsSectionPagesPage from "./pages/documents/section/pages/page/DocumentsSectionPagesPage";
import TrainingPlayerVr from "./pages/training/player/vr/TrainingPlayerVr";
import TrainingCoursesCourseFrame2 from "./pages/training/course/frame2/TrainingCoursesCourseFrame2";
import PeopleGroups from "./pages/people/groups/PeopleGroups";

setupIonicReact();

const App: React.FC = () => {
  return (
    <IonApp>
      <IonReactRouter>
        <IonSplitPane contentId="main">
          {/* <Menu /> */}
          <IonRouterOutlet id="main">
            {/* <Route path="/" exact={true}>
              <Redirect to="/home" />
            </Route> */}
            <Route path="/" exact={true}>
              <Home />
            </Route>
            <Route path="/people/groups" exact={true}>
              <PeopleGroups />
            </Route>
            <Route path="/training/courses" exact={true}>
              <TrainingCourses />
            </Route>
            <Route path="/training/courses/:course" exact={true}>
              <TrainingCoursesCourse />
            </Route>
            <Route path="/training/courses/:course/:frame" exact={true}>
              <TrainingCoursesCourseFrame />
            </Route>
            <Route path="/training/player" exact={true}>
              <TrainingPlayer />
            </Route>
            <Route path="/training/player/vr" exact={true}>
              <TrainingPlayerVr />
            </Route>
            <Route path="/training/player/course/:courseID" exact={true}>
              <TrainingPlayerCourse />
            </Route>
            <Route path="/training/courses/course/frame2" exact={true}>
              <TrainingCoursesCourseFrame2 />
            </Route>
            <Route path="/documents" exact={true}>
              <Documents />
            </Route>
            <Route path="/documents/:documentID" exact={true}>
              <DocumentsSection />
            </Route>
            <Route path="/documents/:documentID/:sectionID" exact={true}>
              <DocumentsSectionPages />
            </Route>
            <Route
              path="/documents/:documentID/:sectionID/:pageID"
              exact={true}
            >
              <DocumentsSectionPagesPage />
            </Route>
          </IonRouterOutlet>
        </IonSplitPane>
      </IonReactRouter>
    </IonApp>
  );
};

export default App;
