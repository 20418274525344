//import { setAssetPath } from "ionicons/dist/types/stencil-public-runtime";
import { Suspense, useEffect, useState } from "react";
// import { useElementSize } from "usehooks-ts";
export interface PodImageProps {
  edits?: any;
  urlOnly?: boolean;
  style?: any;
  id: string;
  width?: number;
  height?: number;
}

const PodImage: React.FC<PodImageProps> = (props) => {
  //const store: any = useSelector((state) => state);
  const [path, setPath] = useState<string>(props.id.replace(/-/g, "/"));
  useEffect(() => {
    // console.log("Path", path);
  }, [[path]]);
  const store = {
    app: {
      serverlessImageHandler: process.env.REACT_APP_MEDIA_URL,
      bucket: process.env.REACT_APP_MEDIA_BUCKET,
    },
  };
  useEffect(() => {
    setPath(props.id.replace(/-/g, "/"));
  }, [props.id]);

  const imageProps = {
    ...{
      bucket: store.app.bucket,
      key: path,
    },
    edits: {
      ...{
        resize: {
          width: props.width ? props.width : 64,
          height: props.height ? props.height : 64,
          fit: "cover",
        },
      },
      ...props.edits,
    },
  };

  const altProps = {
    ...{
      bucket: store.app.bucket,
      key: path,
    },
    edits: {
      ...{
        resize: {
          width: 5,
          height: 5,
          fit: "cover",
        },
      },
      ...props.edits,
    },
  };

  return (
    <Suspense fallback={<></>}>
      {path && store.app.bucket && imageProps && (
        <>
          {props.urlOnly ? (
            store.app.serverlessImageHandler +
            "/" +
            btoa(JSON.stringify(imageProps))
          ) : (
            <img
              alt={`${store.app.serverlessImageHandler}/${btoa(
                JSON.stringify(altProps)
              )}`}
              src={`${store.app.serverlessImageHandler}/${btoa(
                JSON.stringify(imageProps)
              )}`}
              style={props.style}
            ></img>
          )}
        </>
      )}
    </Suspense>
  );
};
export default PodImage;
