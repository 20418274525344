import {
  IonCard,
  IonCardContent,
  IonCardHeader,
  IonCardTitle,
  IonContent,
  IonIcon,
  IonItem,
  IonLabel,
  IonList,
  IonPage,
} from "@ionic/react";
import { bookOutline } from "ionicons/icons";
//import { useParams } from "react-router";
import "./Page.css";
import { useAppSelector } from "../redux/hooks";
import { useGetAppQuery } from "../redux/appQuery";
import { print } from "graphql/language/printer";
import gql from "graphql-tag";
import PodImage from "../components/PodImage";
const Home: React.FC = () => {
  //const { name } = useParams<{ name: string }>();
  const hostname = useAppSelector((state) => state.app.location.hostname);
  const navigationQuery = useGetAppQuery({
    query: GET_NAVIGATION_QUERY,
    variables: {
      id: `model-apps-${hostname.replace(/\./g, "_")}`,
    },
  });

  return (
    <IonPage>
      {/* <IonHeader>
        <IonToolbar>
          <IonButtons slot="start">
            <IonMenuButton />
          </IonButtons>
          <IonTitle>Pili Luna</IonTitle>
        </IonToolbar>
      </IonHeader> */}

      <IonContent fullscreen style={{ "--background": "darkslateblue" }}>
        {/* <IonHeader collapse="condense">
          <IonToolbar>
            <IonTitle size="large">Pili Luna</IonTitle>
          </IonToolbar>
        </IonHeader> */}
        <div
          style={{
            width: "100%",
            display: "flex",
            justifyContent: "space-between",
            padding: 15,
          }}
        >
          <div
            style={{
              width: "100%",
              height: "100%",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            {navigationQuery.isSuccess && (
              <PodImage
                height={32}
                width={64}
                id={`${navigationQuery.data.getPod.join.id}/vos.png`}
                style={{ height: 24 }}
              ></PodImage>
            )}
          </div>
          <div
            style={{
              width: "100%",
              height: "100%",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            {navigationQuery.isSuccess && (
              <PodImage
                height={32}
                width={164}
                id={`${navigationQuery.data.getPod.join.id}/pod.png`}
              ></PodImage>
            )}
          </div>
          <div
            style={{
              width: "100%",
              height: "100%",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            {navigationQuery.isSuccess && (
              <PodImage
                height={24}
                width={24}
                id={`${navigationQuery.data.getPod.join.id}/minister.png`}
              ></PodImage>
            )}
          </div>
        </div>

        {navigationQuery.isSuccess &&
          navigationQuery.data.getPod.join.pods.items.map(
            (section: any, sectionIndex: number) => (
              <IonCard
                key={sectionIndex}
                style={{ "--background": "slateblue" }}
              >
                <IonCardHeader>
                  <IonCardTitle>{section.name}</IonCardTitle>
                </IonCardHeader>
                {section.pods.items.map((item: any, itemIndex: number) => (
                  <IonCard
                    routerLink={`${item.joinID
                      .replace("model-", "")
                      .replace(/-/g, "/")}`}
                    key={item.id}
                    style={{
                      "--background": "RebeccaPurple ",
                      height: "100%",
                    }}
                  >
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: "space-between",
                        padding: 10,
                        height: "100%",
                      }}
                    >
                      <div
                        style={{
                          width: "100%",
                          height: "128",
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "start",
                        }}
                      >
                        <div
                          style={{
                            fontSize: "xx-large",
                            color: "white",
                            fontWeight: "bold",
                          }}
                        >
                          {item.name}
                        </div>
                      </div>
                      <div style={{ height: "100%" }}>
                        <PodImage
                          height={128}
                          width={128}
                          id={`${item.id}/${item.icon}`}
                        ></PodImage>
                      </div>
                    </div>
                  </IonCard>
                ))}
              </IonCard>
            )
          )}
      </IonContent>
    </IonPage>
  );
};

const GET_NAVIGATION_QUERY = print(gql`
  query GET_NAVIGATION_QUERY($id: ID!) {
    getPod(id: $id) {
      id
      join {
        id
        name
        pods(filter: { status: { eq: ACTIVE } }) {
          items {
            id
            name
            icon
            pods(filter: { status: { eq: ACTIVE } }) {
              items {
                id
                joinID
                name
                icon
                join {
                  id
                  joinID
                  name
                  icon
                }
              }
            }
          }
        }
      }
    }
  }
`);
export default Home;
