import {
  IonBackButton,
  IonButton,
  IonButtons,
  IonContent,
  IonFab,
  IonFabButton,
  IonHeader,
  IonIcon,
  IonInput,
  IonItem,
  IonLabel,
  IonList,
  IonModal,
  IonPage,
  IonSpinner,
  IonTitle,
  IonToolbar,
  useIonViewWillEnter,
} from "@ionic/react";
import { useDispatch } from "react-redux";
import { useGetAppQuery, useLazyGetAppQuery } from "../../../redux/appQuery";
import { useAppDispatch, useAppSelector } from "../../../redux/hooks";
import { setGroups } from "../PeopleSlice";
import { print } from "graphql/language/printer";
import gql from "graphql-tag";
import { Pod } from "../../../pod";
import { add } from "ionicons/icons";
import { useEffect, useRef, useState } from "react";
const PeopleGroups = () => {
  const dispatch = useAppDispatch();
  const groups = useAppSelector((state) => state.people.groups);
  const [openAddPerson, setOpenAddPerson] = useState<boolean>(false);
  const [getPeopleGroupsQuery, getPeopleGroupsResult] = useLazyGetAppQuery();
  const page = useRef<any>();
  const modal = useRef<any>();
  const [presentingElement, setPresentingElement] = useState<
    HTMLElement | undefined
  >(undefined);
  useIonViewWillEnter(async () => {
    getPeopleGroupsQuery({
      query: GET_PEOPLE_GROUPS,
      variables: { id: "model-people-groups" },
    }).then((res) => {
      dispatch(setGroups(res.data));
    });
  }, []);
  useEffect(() => {
    setPresentingElement(page.current);
  }, []);
  const dismiss = () => {
    modal.current?.dismiss();
  };
  return (
    <IonPage ref={page}>
      <IonHeader>
        <IonToolbar>
          <IonButtons>
            <IonBackButton></IonBackButton>
          </IonButtons>
          <IonTitle>Groups</IonTitle>
        </IonToolbar>
      </IonHeader>
      <IonContent>
        <div>People Groups</div>
        <IonList>
          {groups ? (
            <>
              {groups.pods?.items.map((group: any) => (
                <IonItem>{group.name}</IonItem>
              ))}
            </>
          ) : (
            <div>
              <IonSpinner />
            </div>
          )}
        </IonList>
      </IonContent>
      <IonFab horizontal="end" vertical="bottom" slot="fixed">
        <IonFabButton onClick={() => setOpenAddPerson(true)}>
          <IonIcon icon={add}></IonIcon>
        </IonFabButton>
      </IonFab>
      <IonModal
        ref={modal}
        onWillDismiss={() => setOpenAddPerson(false)}
        isOpen={openAddPerson}
        presentingElement={presentingElement}
      >
        <CreateUserModal dismiss={dismiss}></CreateUserModal>
      </IonModal>
    </IonPage>
  );
};

const CreateUserModal: React.FC<{ dismiss: () => void }> = (props) => {
  const createUser = () => {
    console.log("UserState", userState);
    props.dismiss();
  };
  const [userState, setUserState] = useState<{
    username: any;
    password: any;
    first_name: any;
    last_name: any;
  }>({ username: "", password: "", first_name: "", last_name: "" });
  return (
    <>
      <IonHeader>
        <IonToolbar>
          <IonButton slot="start" onClick={() => props.dismiss()} fill="clear">
            Close
          </IonButton>
          <IonTitle>New User</IonTitle>
        </IonToolbar>
      </IonHeader>
      <IonContent>
        <IonList>
          <IonItem>
            <IonLabel>Username</IonLabel>
            <IonInput
              value={userState?.username}
              onIonChange={(username) =>
                setUserState({
                  ...userState,
                  ...{ username: username.detail.value },
                })
              }
            ></IonInput>
          </IonItem>
          <IonItem>
            <IonLabel>Password</IonLabel>
            <IonInput
              type="password"
              onIonChange={(password) =>
                setUserState({
                  ...userState,
                  ...{ password: password.detail.value },
                })
              }
            ></IonInput>
          </IonItem>
          <IonItem>
            <IonLabel>First Name</IonLabel>
            <IonInput
              value={userState.first_name}
              onIonChange={(first_name) =>
                setUserState({
                  ...userState,
                  ...{ first_name: first_name.detail.value },
                })
              }
            ></IonInput>
          </IonItem>
          <IonItem>
            <IonLabel>Last Name</IonLabel>
            <IonInput
              value={userState.last_name}
              onIonChange={(last_name) =>
                setUserState({
                  ...userState,
                  ...{ last_name: last_name.detail.value },
                })
              }
            ></IonInput>
          </IonItem>
        </IonList>
        <IonButton expand="block" fill="outline" onClick={createUser}>
          Create
        </IonButton>
      </IonContent>
    </>
  );
};

export default PeopleGroups;

const GET_PEOPLE_GROUPS = print(gql`
  query GET_PEOPLE_GROUPS($id: ID!) {
    getPod(id: $id) {
      id
      pods {
        items {
          id
          name
        }
      }
    }
  }
`);
