import {
  IonAvatar,
  IonButton,
  IonButtons,
  IonCard,
  IonCardContent,
  IonCardHeader,
  IonCardTitle,
  IonContent,
  IonFab,
  IonFabButton,
  IonHeader,
  IonIcon,
  IonInput,
  IonItem,
  IonItemOption,
  IonItemOptions,
  IonItemSliding,
  IonLabel,
  IonList,
  IonModal,
  IonPage,
  IonTextarea,
  IonToolbar,
} from "@ionic/react";
import { addOutline, documentOutline, trashBinOutline } from "ionicons/icons";

import { useEffect, useRef, useState } from "react";
import Header from "../../../../components/Header";
import { useAppSelector } from "../../../../redux/hooks";
import * as changecase from "change-case";
import { useGetAppQuery, useSetAppMutation } from "../../../../redux/appQuery";
import { print } from "graphql/language/printer";
import gql from "graphql-tag";
import { useLocation } from "react-router";
import PodImage from "../../../../components/PodImage";
const DocumentsSectionPage: React.FC = () => {
  const location = useLocation();
  const [addModal, setAddModal] = useState<boolean>(false);
  const [confirmDelete, setConfirmDelete] = useState<number>();
  const page = useRef(undefined);
  const listRef = useRef<any>();
  const modal = useRef<HTMLIonModalElement>(null);
  const [id, setID] = useState<string>(
    `model${location.pathname.replace(/\//g, "-")}`
  );
  const [presentingElement, setPresentingElement] = useState<
    HTMLElement | undefined
  >(undefined);
  useEffect(() => {
    setPresentingElement(page.current);
  }, []);
  const dismiss = () => {
    modal.current?.dismiss();
  };
  const documentsSectionQuery = useGetAppQuery({
    query: GET_DOCUMENTS_QUERY,
    variables: {
      id: id,
    },
  });

  return (
    <IonPage ref={page}>
      <IonContent fullscreen style={{ "--background": "darkslateblue" }}>
        <Header>Pages</Header>
        <IonCard style={{ "--background": "slateblue" }}>
          <IonCardHeader style={{ "--background": "slateblue" }}>
            <IonCardTitle>Pages</IonCardTitle>
          </IonCardHeader>
          <IonCardContent style={{ "--background": "slateblue" }}>
            <IonList ref={listRef} style={{ "--background": "slateblue" }}>
              {documentsSectionQuery.data &&
                documentsSectionQuery.isSuccess &&
                documentsSectionQuery.data.getPod?.pods.items.map(
                  (frame: any) => (
                    <IonItemSliding key={frame.id}>
                      <IonItem
                        style={{ "--background": "rebeccapurple" }}
                        key={frame.id}
                        routerLink={frame.id
                          .replace("model-", "/")
                          .replace(/-/g, "/")}
                      >
                        <IonIcon icon={documentOutline} slot="start"></IonIcon>

                        <IonLabel>
                          <h3>{frame.name}</h3>
                          <p>{frame.desc}</p>
                        </IonLabel>
                      </IonItem>
                      <IonItemOptions side="start">
                        <IonItemOption
                          color="danger"
                          onClick={() => setConfirmDelete(frame.id)}
                        >
                          <IonIcon icon={trashBinOutline}></IonIcon>
                          <IonLabel slot="bottom">Delete</IonLabel>
                        </IonItemOption>
                      </IonItemOptions>
                    </IonItemSliding>
                  )
                )}
            </IonList>
          </IonCardContent>
        </IonCard>
        <IonFab horizontal="end" vertical="bottom">
          <IonFabButton onClick={() => setAddModal(true)}>
            <IonIcon icon={addOutline}></IonIcon>
          </IonFabButton>
        </IonFab>
        <IonModal
          isOpen={addModal}
          canDismiss={true}
          ref={modal}
          presentingElement={presentingElement}
          onWillDismiss={() => setAddModal(false)}
        >
          <AddDocument
            parentID={id}
            order={documentsSectionQuery.data!?.getPod!?.pods!?.items.length}
            dismiss={() => dismiss()}
            refresh={() => documentsSectionQuery.refetch()}
          ></AddDocument>
        </IonModal>
      </IonContent>
    </IonPage>
  );
};

const AddDocument: React.FC<{
  parentID: String;
  dismiss: () => void;
  refresh: () => void;
  order: number;
}> = (props: any) => {
  const appID = useAppSelector((state) => state.app.process.REACT_APP_PREFIX);
  const [createPodMutation, createPodMutationResult] = useSetAppMutation();
  const [showLoading, setShowLoading] = useState<boolean>(false);
  const [pod, setPod] = useState<{
    appID: string;
    parentID: string;
    status: string;
    joinID: string;
    desc: string | null | undefined;
    id: string;
    name: string;
    schemaID: string;
    order: number;
  }>({
    appID: appID,
    name: "",
    parentID: props.parentID,
    status: "ACTIVE",
    joinID: "",
    desc: "",
    id: "",
    schemaID: "schema-documents-section-pages",
    order: props.order + 1,
  });
  useEffect(() => {
    if (createPodMutationResult.status === "fulfilled") {
      setShowLoading(false);
      props.refresh();
      props.dismiss();
    }
  }, [createPodMutationResult]);
  const createDocument = () => {
    const createPod = {
      ...pod,
      ...{
        id: `${props.parentID}-${changecase.snakeCase(pod.name)}`,
        joinID: `${props.parentID}-${changecase.snakeCase(pod.name)}`,
      },
    };
    console.log("Pod", createPod);
    createPodMutation({ query: CREATE_POD_MUTATION, variables: createPod });
    setShowLoading(true);
  };
  return (
    <IonPage>
      <IonContent style={{ "--background": "slateblue" }}>
        <IonHeader>
          <IonToolbar style={{ "--background": "slateblue" }}>
            <IonButtons>
              <IonButton onClick={() => props.dismiss()} color="light">
                Close
              </IonButton>
            </IonButtons>
          </IonToolbar>
        </IonHeader>
        <IonCard style={{ "--background": "darkslateblue" }}>
          <IonCardHeader>
            <IonCardTitle>Create Page</IonCardTitle>
          </IonCardHeader>
          <IonCardContent>
            <IonList>
              <IonItem style={{ "--background": "slateblue" }}>
                <IonLabel position="stacked">Page Name</IonLabel>
                <IonInput
                  value={pod.name}
                  onIonChange={(input: any) =>
                    setPod({ ...pod, ...{ name: input.target.value } })
                  }
                ></IonInput>
              </IonItem>
              <IonItem style={{ "--background": "slateblue" }}>
                <IonLabel position="stacked">Page Description</IonLabel>
                <IonTextarea
                  autoGrow
                  value={pod.desc}
                  onIonChange={(input) =>
                    setPod({ ...pod, ...{ desc: input.target.value } })
                  }
                ></IonTextarea>
              </IonItem>
            </IonList>
            <IonButton
              expand="block"
              fill="outline"
              onClick={() => createDocument()}
            >
              Create
            </IonButton>
          </IonCardContent>
        </IonCard>
      </IonContent>
    </IonPage>
  );
};

const CREATE_POD_MUTATION = print(gql`
  mutation CREATE_POD_MUTATION(
    $appID: ID!
    $id: ID!
    $desc: String!
    $joinID: ID!
    $name: String
    $order: Int!
    $parentID: ID!
    $schemaID: ID!
  ) {
    createPod(
      input: {
        appID: $appID
        id: $id
        desc: $desc
        joinID: $joinID
        name: $name
        order: $order
        parentID: $parentID
        schemaID: $schemaID
        status: ACTIVE
      }
    ) {
      id
    }
  }
`);

const GET_DOCUMENTS_QUERY = print(gql`
  query GET_DOCUMENTS_QUERY($id: ID!) {
    getPod(id: $id) {
      id
      name
      desc
      pods {
        items {
          id
          name
          icon
          order
          desc
        }
      }
    }
  }
`);

export default DocumentsSectionPage;
