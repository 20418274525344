import { useRef, useState, useEffect } from "react";
import Frame from "../../../../components/Frame";
import { useLazyGetAppQuery } from "../../../../redux/appQuery";
import { print } from "graphql/language/printer";
import gql from "graphql-tag";
import shortuuid from "short-uuid";
import { useAppDispatch, useAppSelector } from "../../../../redux/hooks";
import { useLocation } from "react-router";
import axios from "axios";
import { setDesc, setIcon } from "./TrainingCoursesCourseFrameSlice";
import { Pod } from "../../../../pod";
import { customAlphabet } from "nanoid";

import {
  IonButton,
  IonButtons,
  IonCard,
  IonCardContent,
  IonContent,
  IonHeader,
  IonLoading,
  IonModal,
  IonPage,
  IonTextarea,
  IonTitle,
  IonToolbar,
} from "@ionic/react";
const TrainingCoursesCourseFrameImagetext: React.FC<{
  refetch: () => void;
  pod: Pod;
  pageRef: any;
}> = (props) => {
  // Generals
  const location = useLocation();
  const uploadButtonRef = useRef<any>(null);
  // Redux
  const Bucket = useAppSelector(
    (state) => state.app.process.REACT_APP_MEDIA_BUCKET
  );
  const config = useAppSelector(
    (state) => state["training-courses-course-frame"].config
  );

  const framePod = props.pod;
  const course = useAppSelector(
    (state) => state["training-courses-course-frame"].framePod.parent
  );
  // State
  const page = useRef(undefined);

  const [imageToUpload, setImageToUpload] = useState<any>();
  const [iconName, setIconName] = useState<string>();
  const [id, setID] = useState<string>(
    `model${location.pathname.replace(/\//g, "-")}`
  );
  // Queries
  const [updateIconQuery, updateIconQueryResult] = useLazyGetAppQuery();
  const [updateDescQuery, updateDescQueryResult] = useLazyGetAppQuery();
  const [createPresignedPost, createPresignedPostResult] = useLazyGetAppQuery();
  const [detectLabels, detectLabelsResult] = useLazyGetAppQuery();
  const [getTextFromImage, getTextFromImageResult] = useLazyGetAppQuery();
  const [getNarration, getNarrationResult] = useLazyGetAppQuery();
  const [updateString, updateStringResult] = useLazyGetAppQuery();
  // Redux
  const dispatch = useAppDispatch();
  // Modals
  const [openTextEditorModal, setOpenTextEditorModal] =
    useState<boolean>(false);
  const [presentingElement, setPresentingElement] = useState<
    HTMLElement | undefined
  >(undefined);
  const textEditorModalRef = useRef<any>(null);
  // Effects

  useEffect(() => {
    if (imageToUpload) {
      dispatch(setIcon("Loading"));
      ExtractTextFromImageSequence();
    }
  }, [imageToUpload]);

  const ExtractTextFromImageSequence = async () => {
    const nanoid = customAlphabet("1234567890abcdef", 10);
    let icon = `${nanoid()}.jpg`;

    // const icon = `${shortuuid.generate()}.jpg`;
    setIconName(icon);
    const key = `${id}-${icon}`;
    const variables = {
      Bucket: Bucket,
      ContentType: "application/octet-stream",
      Key: key.replace(/-/g, "/"),
    };
    const presignedPost = await CreatePresignedPost(variables);
    const axiosPostResult = await AxiosPostImage(
      JSON.parse(presignedPost.data.aws)
    );
    const UpdateIconQueryResult = await UpdateIconQuery(icon);
    console.log("UpdateIconQueryResult", UpdateIconQueryResult);
    props.refetch();
    const labelsQueryResult = await DetectLabelsQuery(icon);
    const labelData = JSON.parse(labelsQueryResult.data.aws);
    const labelList = labelData!
      ?.Labels!?.filter((label: any) => parseInt(label.Confidence) > 65)
      .sort((a: any, b: any) => parseInt(a.Confidence) + parseInt(b.Confidence))
      .map((a: any) => a.Name)
      .toString();
    const prompt = `You are a professional instructional designer creating the script for a multimedia course titled ${
      course.name
    }  ${
      course.desc ? " with the following description: " + course.desc : ""
    }. Write the script that goes with a frame called ${
      framePod.name
    } that has image with the following labels: ${labelList}. Respond in formal academic Spanish language and limit your response to 300 characters in json format with the following structure: {"repsponse": RESPONSE_HERE}`;
    dispatch(setDesc("Loading"));
    const GetTextFromImageResult = await GetTextFromImage(prompt);

    const desc = JSON.parse(
      JSON.parse(GetTextFromImageResult.data.openai).body.choices[0].text
    ).response;

    const UpdateDescQueryResult = await UpdateDescQuery(desc);
    await createNarration(UpdateDescQueryResult.data.updatePod.desc);
    props.refetch();
  };

  const UpdateDescQuery = (desc: String) => {
    return updateDescQuery({
      query: UPDATE_DESC,
      variables: { id: id, desc: desc },
    });
  };

  const GetTextFromImage = (prompt: String) => {
    return getTextFromImage({
      query: GET_TEXT_FROM_IMAGE,
      variables: {
        temperature: 0.75,
        max_tokens: 1024,
        prompt: prompt,
        model: "text-davinci-003",
        user: course.id,
      },
    });
  };

  const CreatePresignedPost = (variables: any) => {
    return createPresignedPost({
      query: PUT_SIGNED_URL,
      variables: variables,
    });
  };

  const AxiosPostImage = (presignedUrl: string) => {
    return axios.put(presignedUrl, imageToUpload, {
      headers: {
        "Content-Type": "application/octet-stream",
      },
    });
  };

  const UpdateIconQuery = (iconName: string | undefined) => {
    return updateIconQuery({
      query: UPDATE_ICON,
      variables: { id: id, icon: iconName },
    });
  };

  const DetectLabelsQuery = (iconName: string | undefined) => {
    return detectLabels({
      query: REKOGNITION_DETECT_LABELS,
      variables: {
        Bucket: Bucket,
        Name: `${id.replace(/-/g, "/")}/${iconName}`,
      },
    });
  };

  //   useEffect(() => {
  //     if (iconName && createPresignedPostResult.isSuccess) {
  //       const presignedUrl = JSON.parse(createPresignedPostResult.data.aws);
  //       axios
  //         .put(presignedUrl, imageToUpload, {
  //           headers: {
  //             "Content-Type": "application/octet-stream",
  //           },
  //         })
  //         .then((res) => {
  //           //dispatch(setIcon(iconName))
  //           updateIconQuery({
  //             query: UPDATE_ICON,
  //             variables: { id: id, icon: iconName },
  //           });
  //         });
  //     }
  //   }, [createPresignedPostResult, iconName]);

  //   useEffect(() => {
  //     if (updateIconQueryResult.isSuccess) {
  //       props.refetch();
  //       if (config.ai.enabled) {
  //         detectLabels({
  //           query: REKOGNITION_DETECT_LABELS,
  //           variables: {
  //             Bucket: Bucket,
  //             Name: `${id.replace(/-/g, "/")}/${iconName}`,
  //           },
  //         });
  //       }
  //     }
  //   }, [updateIconQueryResult]);

  //   useEffect(() => {
  //     if (detectLabelsResult.isSuccess) {
  //       ///
  //       const labelData = JSON.parse(detectLabelsResult.data.aws);

  //       const labelList = labelData!
  //         ?.Labels!?.filter((label: any) => parseInt(label.Confidence) > 65)
  //         .sort(
  //           (a: any, b: any) => parseInt(a.Confidence) + parseInt(b.Confidence)
  //         )
  //         .map((a: any) => a.Name)
  //         .toString();

  //       const prompt = `You are a professional instructional designer creating the script for a multimedia course titled ${
  //         course.name
  //       }  ${
  //         course.desc ? " with the following description: " + course.desc : ""
  //       }. Write the script that goes with a frame called ${
  //         framePod.name
  //       } that has image with the following labels: ${labelList}. Respond in formal academic Spanish language and limit your response to 300 characters in json format with the following structure: {"repsponse": RESPONSE_HERE}`;

  //       //   const prompt = `Usted es in disenador instruccional profesional creando un guion para una capacitacion multimedia llamada "${
  //       //     course.name
  //       //   }". ${
  //       //     course.desc ? " que tiene esta descripccion: " + course.desc : ""
  //       //   }. Cree el texto instruccional para un marco con la siguiente descripccion "${
  //       //     framePod.name
  //       //   }". La pantalla tiene una imagen tiene los siguientes elementos (en Ingles): ${labelList}. El orden de relevancia es: Nombre y descripccion del curso, nombre de la pantalla y elementos de image. Responda en Espanol formal y academico. Limite su respuesta a 300 caracteres`;
  //       console.log("Prompt", prompt);
  //       getTextFromImage({
  //         query: GET_TEXT_FROM_IMAGE,
  //         variables: {
  //           temperature: 0.75,
  //           max_tokens: 1024,
  //           prompt: prompt,
  //           model: "text-davinci-003",
  //           user: course.id,
  //         },
  //       });
  //     }
  //   }, [detectLabelsResult]);

  //   useEffect(() => {
  //     console.log("get text from image", getTextFromImageResult);
  //     if (getTextFromImageResult.isSuccess) {
  //       const response = JSON.parse(
  //         JSON.parse(getTextFromImageResult.data.openai).body.choices[0].text
  //       ).response;
  //       updateDescQuery({
  //         query: UPDATE_DESC,
  //         variables: { id: id, desc: response },
  //       });
  //     }
  //     if (getTextFromImageResult.isFetching) {
  //       dispatch(setDesc("Loading"));
  //     }
  //   }, [getTextFromImageResult]);

  useEffect(() => {
    if (updateDescQueryResult.isSuccess) {
      createNarration(updateDescQueryResult.data.updatePod.desc);
    }
  }, [updateDescQueryResult]);

  const createNarration = (text: string) => {
    var narrationFileId = shortuuid.generate();
    const variables = {
      Bucket: Bucket,
      Key: `${id.replace(/-/g, "/")}/${narrationFileId}.mp3`,
      LanguageCode: "es-US",
      Text: `<speak><amazon:domain name="conversational">${text}</amazon:domain></speak>`,
      VoiceId: "Lupe",
    };
    console.log("Narration", variables);
    return getNarration({ query: CREATE_NARRATION, variables: variables }).then(
      (res) => {
        updateString({
          query: UPDATE_STRING,
          variables: { id: id, string: `${narrationFileId}.mp3` },
        });
      }
    );
  };

  useEffect(() => {
    if (getNarrationResult.isSuccess) {
      props.refetch();
    }
  }, [getNarrationResult]);

  // Functions
  const dismiss = () => {
    textEditorModalRef.current?.dismiss();
  };

  return (
    <>
      <IonContent>
        <Frame
          clickedOnImage={() => {
            console.log("Clicked on image");
            uploadButtonRef.current.click();
          }}
          pod={framePod}
          clickedOnText={() => {
            setOpenTextEditorModal(true);
          }}
          active={true}
          play={() => console.log("Playing")}
        ></Frame>
        <input
          type="file"
          onChange={(file: any) => setImageToUpload(file.target.files[0])}
          ref={uploadButtonRef}
          style={{ display: "none" }}
        />
      </IonContent>
      <IonModal
        isOpen={openTextEditorModal === true ? true : false}
        ref={textEditorModalRef}
        presentingElement={presentingElement!}
        onWillDismiss={() => setOpenTextEditorModal(false)}
        canDismiss={true}
      >
        <TextEditor
          pod={framePod}
          dismiss={() => dismiss()}
          refetch={() => props.refetch()}
          createNarration={(text) => createNarration(text)}
        ></TextEditor>
      </IonModal>
    </>
  );
};

const TextEditor: React.FC<{
  pod: any;
  dismiss: () => void;
  refetch: () => void;
  createNarration: (text: string) => void;
}> = (props) => {
  const [updateDescQuery, updateDescQueryResult] = useLazyGetAppQuery();
  const [updateIconQuery, updateIconQueryResult] = useLazyGetAppQuery();
  const [downloadImage, downloadImageResult] = useLazyGetAppQuery();
  const [loading, setLoading] = useState<boolean>(false);
  const [generateImageFromText, generateImageFromTextResult] =
    useLazyGetAppQuery();
  const location = useLocation();
  const [text, setText] = useState<any>("");
  const [id, setID] = useState<string>(
    `model${location.pathname.replace(/\//g, "-")}`
  );
  useEffect(() => {
    setText(props.pod.desc);
  }, []);
  const submit = () => {
    setLoading(true);
    updateDescQuery({
      query: UPDATE_DESC,
      variables: { id: id, desc: text },
    }).then(() => {
      generateImageFromText({
        query: GENERATE_IMAGE_FROM_TEXT,
        variables: {
          prompt: text,
        },
      }).then((res) => {
        const imageResult = JSON.parse(res.data.openai);
        const url = imageResult.body.data[0].url;
        const fileName = `${shortuuid.generate()}.jpg`;
        const key = `${id.replace(/-/g, "/")}/${fileName}`;
        const downloadFileVariables = {
          contentType: "image/jpeg",
          key: key,
          url: url,
        };
        downloadImage({
          query: DOWNLOAD_FILE,
          variables: downloadFileVariables,
        }).then((res) => {
          updateIconQuery({
            query: UPDATE_ICON,
            variables: { id: id, icon: fileName },
          }).then((res) => {
            console.log("Res", res);
            props.createNarration(text);
            props.dismiss();
            props.refetch();
            setLoading(false);
          });
        });
      });
    });
  };
  return (
    <IonPage>
      <IonContent
        style={{ width: "100%", height: "100%", "--background": "slateblue" }}
      >
        <IonHeader>
          <IonToolbar style={{ "--background": "darkslateblue" }}>
            <IonButtons slot="start">
              <IonButton color="light" onClick={() => props.dismiss()}>
                Close
              </IonButton>
            </IonButtons>
            <IonTitle>Edit Text</IonTitle>
          </IonToolbar>
        </IonHeader>
        <IonCard style={{ "--background": "darkslateblue" }}>
          <IonCardContent>
            <IonTextarea
              rows={20}
              value={text}
              onIonChange={(ev) => setText(ev.detail.value)}
              style={{
                color: "white",
                padding: 5,
                borderColor: "white",
                borderWidth: 2,
                borderStyle: "solid",
                borderRadius: 10,
              }}
            ></IonTextarea>
            <IonButton
              onClick={() => submit()}
              expand="block"
              fill="outline"
              color="light"
            >
              Submit
            </IonButton>
          </IonCardContent>
        </IonCard>
        <IonLoading
          isOpen={loading}
          onDidDismiss={() => setLoading(false)}
          message={"Please Wait..."}
        />
      </IonContent>
    </IonPage>
  );
};

const PUT_SIGNED_URL = print(gql`
  mutation GET_SIGNED_URL($Bucket: String, $Key: String, $ContentType: String) {
    aws(
      service: {
        S3: {
          getSignedUrl: {
            putObject: { Bucket: $Bucket, Key: $Key, ContentType: $ContentType }
          }
        }
      }
    )
  }
`);

const DOWNLOAD_FILE = print(gql`
  mutation DOWNLOAD_FILE($contentType: String, $key: String!, $url: String!) {
    files(downloadToS3: { key: $key, url: $url, contentType: $contentType })
  }
`);

const GENERATE_IMAGE_FROM_TEXT = print(gql`
  mutation GENERATE_IMAGE($prompt: String!) {
    openai(images: { generations: { prompt: $prompt } })
  }
`);

const GET_TEXT_FROM_IMAGE = print(gql`
  mutation GET_TEXT_FROM_IMAGE(
    $temperature: Float
    $prompt: String!
    $max_tokens: Int
    $frequency_penalty: Float
    $model: String
    $presence_penalty: Float
    $user: String
  ) {
    openai(
      completions: {
        prompt: $prompt
        temperature: $temperature
        max_tokens: $max_tokens
        frequency_penalty: $frequency_penalty
        presence_penalty: $presence_penalty
        model: $model
        stream: false
        user: $user
      }
    )
  }
`);

const UPDATE_ICON = print(gql`
  mutation UPDATE_ICON($icon: String, $id: ID!) {
    updatePod(input: { id: $id, icon: $icon }) {
      id
      icon
    }
  }
`);

const UPDATE_DESC = print(gql`
  mutation UPDATE_DESC($desc: String, $id: ID!) {
    updatePod(input: { id: $id, desc: $desc }) {
      id
      desc
    }
  }
`);

const UPDATE_STRING = print(gql`
  mutation UPDATE_STRING($string: String, $id: ID!) {
    updatePod(input: { id: $id, string: $string }) {
      id
      string
    }
  }
`);

const REKOGNITION_DETECT_LABELS = print(gql`
  mutation RekognitionDetectLabels($Bucket: String!, $Name: String!) {
    aws(
      service: {
        Rekognition: {
          detectLabels: {
            Image: { S3Object: { Bucket: $Bucket, Name: $Name } }
          }
        }
      }
    )
  }
`);

const CREATE_NARRATION = print(gql`
  mutation CREATE_NARRATION(
    $Bucket: String!
    $Key: String!
    $LanguageCode: String
    $Text: String!
    $VoiceId: AwsMutationPollyVoiceId = Lupe
  ) {
    aws(
      service: {
        Polly: {
          pollyToS3: {
            Bucket: $Bucket
            Engine: neural
            Key: $Key
            LanguageCode: $LanguageCode
            OutputFormat: mp3
            Text: $Text
            TextType: ssml
            VoiceId: $VoiceId
          }
        }
      }
    )
  }
`);

export default TrainingCoursesCourseFrameImagetext;
