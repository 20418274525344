import {
  IonBackButton,
  IonButtons,
  IonHeader,
  IonMenuButton,
  IonTitle,
  IonToolbar,
} from "@ionic/react";
const TrainingCoursesCourseFrame: React.FC<{ children?: any }> = ({
  children,
}) => {
  return (
    <IonHeader translucent={true}>
      <IonToolbar style={{ "--background": "darkslateblue" }}>
        <IonButtons slot="start">
          <IonBackButton color="light"></IonBackButton>
        </IonButtons>

        <IonTitle>{children}</IonTitle>
        <IonButtons slot="end">
          <IonMenuButton />
        </IonButtons>
      </IonToolbar>
    </IonHeader>
  );
};

export default TrainingCoursesCourseFrame;
