import { IonContent, IonPage } from "@ionic/react";
//import "node_modules/dist/device-mockups.min.css";
import "html5-device-mockups";
const TrainingCoursesCourseFrame2 = () => {
  return (
    <IonPage>
      <IonContent>
        <div className="device-wrapper">
          <div
            className="device"
            data-device="iPhone5"
            data-orientation="portrait"
            data-color="black"
          >
            <div className="screen">hello</div>
            <div className="button"></div>
          </div>
        </div>
      </IonContent>
    </IonPage>
  );
};
export default TrainingCoursesCourseFrame2;
