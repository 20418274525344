import { IonCol, IonSpinner } from "@ionic/react";
import PodImage from "./PodImage";
import ReactPlayer from "react-player";
import { useAppSelector } from "../redux/hooks";
import { useEffect, useState } from "react";
import useFitText from "use-fit-text";
import ScaleText from "react-scale-text";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css/navigation";
import "swiper/css/pagination";
import "swiper/css/scrollbar";
import "swiper/css/effect-flip";
import "swiper/css/effect-coverflow";
import "swiper/css/effect-creative";
import "swiper/css/effect-creative";
import "swiper/css";
import {
  EffectCards,
  EffectCreative,
  EffectCube,
  EffectFade,
  EffectFlip,
  Navigation,
  Pagination,
  Thumbs,
} from "swiper";
const Frame: React.FC<{
  pod: any;
  active: boolean;
  playNarration?: boolean;
  setPlayNarration?: (value: boolean) => void;
  play: (play: boolean) => void;
  clickedOnImage: () => void;
  clickedOnText: () => void;
}> = (props) => {
  const distribution = useAppSelector(
    (state) => state.app.process.REACT_APP_DISTRIBUTION
  );
  const [playAudio, setPlayAudio] = useState<boolean>(false);
  const { fontSize, ref } = useFitText();
  const [iconMode, setIconMode] = useState<number>(0);
  const [swiper, setSwiper] = useState<any>();
  const [textSwiper, setTextSwiper] = useState<any>();
  useEffect(() => {
    if (props.playNarration === true && props.active === true) {
      setPlayAudio(true);
      setPlayAudio(false);
    }
  }, [props.playNarration]);

  useEffect(() => {
    console.log("props.pod.icon", props.pod.icon);
    if (swiper) {
      if (props.pod.icon) {
        if (props.pod.icon === "Loading") {
          swiper.slideTo(1, 500);
        } else {
          swiper.slideTo(2, 500);
        }
      } else {
        swiper.slideTo(0, 500);
      }
    }
  }, [props.pod.icon, swiper]);

  useEffect(() => {
    if (textSwiper) {
      if (props.pod.desc) {
        if (props.pod.desc === "Loading") {
          textSwiper.slideTo(1, 500);
        } else {
          textSwiper.slideTo(2, 500);
        }
      } else {
        textSwiper.slideTo(0, 500);
      }
    }
  }, [props.pod.desc, swiper]);

  return (
    <div
      style={{
        width: "100%",
        height: "100%",
        padding: 20,
        backgroundColor: "darkslateblue",
        borderRadius: 10,
      }}
    >
      <div
        style={{
          padding: 0,
          display: "flex",
          flexDirection: "column",
          height: "100%",
          width: "100%",
          justifyContent: "space-between",
        }}
      >
        <div
          style={{
            padding: 0,
            width: "100%",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            height: "100%",
          }}
          onClick={() => {
            props.clickedOnImage();
          }}
        >
          <Swiper
            className="fullsize text-white padding-x-small"
            modules={[Navigation, Pagination, EffectFlip, Thumbs]}
            effect="flip"
            spaceBetween={0}
            slidesPerView={1}
            navigation={false}
            watchSlidesProgress
            onSwiper={setSwiper}
            onSlideChange={(slide) => {}}
            allowTouchMove={false}
          >
            <SwiperSlide>
              <img
                src="/colorbars.jpg"
                alt="No image defined"
                style={{ width: "100%", borderRadius: 20 }}
              ></img>
            </SwiperSlide>
            <SwiperSlide
              style={{
                width: "100%",
                height: "100%",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <IonSpinner></IonSpinner>
            </SwiperSlide>
            <SwiperSlide>
              {props.pod.icon !== "Loading" ? (
                <PodImage
                  id={`${props.pod.id}-${props.pod.icon}`}
                  width={256}
                  height={256}
                  style={{ width: "100%", height: "100%", borderRadius: 20 }}
                ></PodImage>
              ) : null}
            </SwiperSlide>
          </Swiper>
        </div>
        <div
          style={{
            textAlign: "center",
            fontWeight: "bold",
            padding: 10,
            height: "100%",
          }}
        >
          {props.pod.name}
        </div>
        <div>
          <Swiper
            onClick={() => {
              props.clickedOnText();
            }}
            modules={[Navigation, Pagination, EffectFlip, Thumbs]}
            effect="flip"
            spaceBetween={0}
            slidesPerView={1}
            navigation={false}
            watchSlidesProgress
            onSwiper={setTextSwiper}
            onSlideChange={(slide) => {}}
            allowTouchMove={false}
            style={{
              width: "100%",
              height: "100%",
              borderRadius: 20,
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <SwiperSlide
              style={{
                backgroundColor: "slateblue",
                borderRadius: 20,
                height: "100%",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              EMPTY
            </SwiperSlide>
            <SwiperSlide
              style={{
                width: "100%",
                height: "100%",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <img src="/siriloader.gif" style={{ borderRadius: 20 }}></img>
            </SwiperSlide>
            <SwiperSlide>
              <div
                onClick={() => {
                  props.clickedOnText();
                }}
                style={{
                  width: "100%",
                  height: "100%",
                  backgroundColor: "navy",
                  flexDirection: "column",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  textAlign: "center",
                  color: "goldenrod",
                  fontWeight: "bold",
                  padding: 15,
                  verticalAlign: "middle",
                  borderRadius: 20,
                  fontSize: props.pod.desc
                    ? props.pod.desc < 40
                      ? "xxx-large"
                      : props.pod.desc.length >= 0 &&
                        props.pod.desc.length <= 50
                      ? "xx-large"
                      : props.pod.desc.length >= 50 &&
                        props.pod.desc.length <= 100
                      ? "x-large"
                      : props.pod.desc.length >= 100 &&
                        props.pod.desc.length <= 150
                      ? "large"
                      : props.pod.desc.length >= 150 &&
                        props.pod.desc.length <= 300
                      ? "medium"
                      : "small"
                    : "medium",
                }}
              >
                {/* <div style={{ fontSize: "xx-small" }}>{props.pod.desc.length}</div> */}
                {props.pod.desc ? <>{props.pod.desc}</> : <></>}
              </div>
            </SwiperSlide>
          </Swiper>
        </div>
      </div>
      {/* {props.pod.string} */}
      {props.pod.string && (
        <IonCol style={{ color: "white" }} hidden>
          <ReactPlayer
            onStart={() => {
              props.play(true);
            }}
            onEnded={() => {
              console.log("Stopped");
              props.play(false);
            }}
            playing={props.active || playAudio}
            controls={false}
            autoPlay={true}
            url={`https://${distribution}/${props.pod.id.replace(
              /-/g,
              "/"
            )}/${props.pod.string.replace(/-/g, "/")}`}
          />
        </IonCol>
      )}
    </div>
  );
};
export default Frame;
