import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/dist/query";
import { type } from "os";
import { Pod } from "../../pod";

import { api, appApi, useGetAppQuery } from "../../redux/appQuery";

// export const getPeopleGroups = createAsyncThunk(
//   "people/getPeopleGroups",
//   async (init, thunkAPI) => {
//     console.log("Get Groups", init);
//     return init;
//   }
// );

export const getPeopleGroups = createAsyncThunk(
  "people/getGroups",
  async (id: string, thunkAPI) => {
    // return api.({
    //   query: GET_PEOPLE_GROUPS,
    //   variables: {
    //     id: "model-people-groups",
    //   },
    // });
  }
);

type initialStateTypes = {
  init: Boolean;
  groups: Pod | undefined | null;
};

const initialState: initialStateTypes = {
  init: false,
  groups: undefined,
};

export const peopleSlice = createSlice({
  name: "people",
  initialState,
  reducers: {
    setInit: (state, action: PayloadAction<boolean>) => {
      state.init = action.payload;
    },
    setGroups: (state, action: PayloadAction<Pod>) => {
      state.groups = action.payload;
    },
  },
});

export const { setInit, setGroups } = peopleSlice.actions;

export default peopleSlice.reducer;
