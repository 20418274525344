import {
  IonAvatar,
  IonButton,
  IonButtons,
  IonCard,
  IonCardContent,
  IonCardHeader,
  IonCardTitle,
  IonContent,
  IonFab,
  IonFabButton,
  IonHeader,
  IonIcon,
  IonInput,
  IonItem,
  IonItemOption,
  IonItemOptions,
  IonItemSliding,
  IonLabel,
  IonList,
  IonModal,
  IonPage,
  IonTextarea,
  IonToolbar,
} from "@ionic/react";
import {
  addOutline,
  documentOutline,
  saveOutline,
  trashBinOutline,
} from "ionicons/icons";

import { useEffect, useRef, useState } from "react";
import Header from "../../../../../components/Header";
import { useAppSelector } from "../../../../../redux/hooks";
import * as changecase from "change-case";
import {
  useGetAppQuery,
  useSetAppMutation,
} from "../../../../../redux/appQuery";
import { print } from "graphql/language/printer";
import gql from "graphql-tag";
import { useLocation } from "react-router";
import PodImage from "../../../../../components/PodImage";
import { Editor, EditorState } from "draft-js";
import "draft-js/dist/Draft.css";
import React from "react";

import { CKEditor } from "@ckeditor/ckeditor5-react";
import InlineEditor from "@ckeditor/ckeditor5-build-inline";
//import FullEditor from "ckeditor5-custom-build-full/build/ckeditor.js";

import { SwiperSlide, Swiper } from "swiper/react";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import "swiper/css/scrollbar";
import { Navigation, Pagination, Scrollbar, A11y } from "swiper";
const DocumentsSectionPagesPage: React.FC = () => {
  const location = useLocation();
  const [addModal, setAddModal] = useState<boolean>(false);
  const [confirmDelete, setConfirmDelete] = useState<number>();
  const page = useRef(undefined);
  const listRef = useRef<any>();
  const modal = useRef<HTMLIonModalElement>(null);
  const [id, setID] = useState<string>(
    `model${location.pathname.replace(/\//g, "-")}`
  );
  const [value, setValue] = useState("");
  const [presentingElement, setPresentingElement] = useState<
    HTMLElement | undefined
  >(undefined);
  const [editorState, setEditorState] = React.useState(() =>
    EditorState.createEmpty()
  );
  useEffect(() => {
    setPresentingElement(page.current);
  }, []);
  const dismiss = () => {
    modal.current?.dismiss();
  };
  const documentsSectionQuery = useGetAppQuery({
    query: GET_DOCUMENTS_QUERY,
    variables: {
      id: id,
    },
  });

  var options = {
    debug: "info",
    modules: {
      toolbar: "#toolbar",
    },
    placeholder: "Compose an epic...",
    readOnly: true,
    theme: "snow",
  };

  return (
    <IonPage ref={page}>
      <IonContent fullscreen style={{ "--background": "darkslateblue" }}>
        <Header>Page</Header>
        <Swiper
          style={{ height: "90%", width: "90%" }}
          modules={[Navigation, Pagination, Scrollbar, A11y]}
          slidesPerView={1}
          navigation
          pagination={{ clickable: true }}
          scrollbar={{ draggable: true }}
        >
          <SwiperSlide style={{ width: "100%", height: "100%" }}>A</SwiperSlide>
          <SwiperSlide style={{ height: "100%", width: "100%" }}>
            <CKEditor
              editor={InlineEditor}
              data="<p>Hello from CKEditor 5!</p>"
              onReady={(editor: any) => {
                console.log(
                  "CKEditor5 React Component is ready to use!",
                  editor
                );
              }}
              onChange={(event: any, editor: any) => {
                const data = editor.getData();
                console.log({ event, editor, data });
              }}
            />
          </SwiperSlide>
        </Swiper>

        {/* <IonButton onClick={() => console.log("value", value)}></IonButton> */}
        <IonFab horizontal="end" vertical="bottom">
          <IonFabButton onClick={() => setAddModal(true)}>
            <IonIcon icon={saveOutline}></IonIcon>
          </IonFabButton>
        </IonFab>
      </IonContent>
    </IonPage>
  );
};

const CREATE_POD_MUTATION = print(gql`
  mutation CREATE_POD_MUTATION(
    $appID: ID!
    $id: ID!
    $desc: String!
    $joinID: ID!
    $name: String
    $order: Int!
    $parentID: ID!
    $schemaID: ID!
  ) {
    createPod(
      input: {
        appID: $appID
        id: $id
        desc: $desc
        joinID: $joinID
        name: $name
        order: $order
        parentID: $parentID
        schemaID: $schemaID
        status: ACTIVE
      }
    ) {
      id
    }
  }
`);

const GET_DOCUMENTS_QUERY = print(gql`
  query GET_DOCUMENTS_QUERY($id: ID!) {
    getPod(id: $id) {
      id
      name
      desc
      pods {
        items {
          id
          name
          icon
          order
          desc
        }
      }
    }
  }
`);

export default DocumentsSectionPagesPage;
