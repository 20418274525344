import { configureStore } from "@reduxjs/toolkit";
import { setupListeners } from "@reduxjs/toolkit/dist/query";
import peopleSlice from "../pages/people/PeopleSlice";
import TrainingCoursesCourseFrameSlice from "../pages/training/course/frame/TrainingCoursesCourseFrameSlice";
import trainingCoursesSlice from "../pages/training/trainingCoursesSlice";
import { appApi } from "./appQuery";
import appSlice from "./appSlice";

export const store = configureStore({
  reducer: {
    app: appSlice,
    [appApi.reducerPath]: appApi.reducer,
    "training-courses": trainingCoursesSlice,
    "training-courses-course-frame": TrainingCoursesCourseFrameSlice,
    people: peopleSlice,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware().concat(appApi.middleware),
});
setupListeners(store.dispatch);
// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>;
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch;
