import {
  IonButton,
  IonCol,
  IonContent,
  IonGrid,
  IonPage,
  IonRow,
} from "@ionic/react";
import { useEffect, useRef, useState } from "react";
import Header from "../../../../components/Header";

import { print } from "graphql/language/printer";
import gql from "graphql-tag";
import { useGetAppQuery } from "../../../../redux/appQuery";
import { useLocation, useParams } from "react-router";
// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";
import ReactAudioPlayer from "react-audio-player";
// Import Swiper styles
import "swiper/css";
import Frame from "../../../../components/Frame";
import { useAppSelector } from "../../../../redux/hooks";
const TrainingPlayerCourse: React.FC = () => {
  const frameRef = useRef<any>();
  const { courseID } = useParams<{ courseID: string }>();
  const location = useLocation();
  const page = useRef();
  const [swiper, setSwiper] = useState<any>();
  const [showBackButton, setShowBackButton] = useState<boolean>(false);
  const [narration, setNarration] = useState<string>();
  const [id, setID] = useState<string>(
    `model${location.pathname.replace(/\//g, "-")}`
  );
  const [swiperFrame, setSwiperFrame] = useState<any>();
  const [playNarration, setPlayNarration] = useState<boolean>(false);
  const distribution = useAppSelector(
    (state) => state.app.process.REACT_APP_DISTRIBUTION
  );
  const [play, setPlay] = useState<boolean>(false);
  const courseQuery = useGetAppQuery({
    query: COURSES,
    variables: {
      id: courseID,
    },
  });

  // useEffect(() => {
  //   console.log("start", swiper);
  //   setSwiper(swiper);
  //   if (swiper) {
  //     //setShowBackButton(swiper.isBeginning);
  //   }
  // }, [swiper]);

  const processSwiper = (swiper: any) => {
    console.log("Swiper Changed", swiper);
    if (swiper) {
      setSwiper(swiper);
    }
  };

  // useEffect(() => {
  //   console.log("courseQuery", courseQuery, id);
  //   if (courseQuery.isSuccess && swiper.activeIndex) {
  //     setNarration(
  //       `https://${distribution}/${courseQuery.data.getPod.pods.items[
  //         swiper.activeIndex
  //       ].id.replace(/-/g, "/")}/${
  //         courseQuery.data.getPod.pods.items[swiper.activeIndex].string
  //       }`
  //     );
  //   }
  // }, [courseQuery, swiper]);
  return (
    <IonPage ref={page}>
      <Header>{courseQuery.isSuccess && courseQuery.data.getPod.name}</Header>

      <IonContent fullscreen>
        {courseQuery.isSuccess && (
          <div
            style={{
              width: "100%",
              height: "100%",
              display: "flex",
              flexDirection: "column",
              backgroundColor: "navy",
            }}
          >
            <Swiper
              style={{ width: "100%", height: "100%" }}
              spaceBetween={50}
              slidesPerView={1}
              onSlideChange={(swiperFrame) => {
                processSwiper(swiperFrame);
              }}
              onSwiper={(swiper) => {
                setSwiper(swiper);
              }}
              allowTouchMove={false}
            >
              {courseQuery.data.getPod.pods.items.map((frame: any) => (
                <SwiperSlide
                  key={frame.id}
                  style={{ height: "100%", width: "100%" }}
                >
                  {({ isActive }) => (
                    <Frame
                      clickedOnText={() => {}}
                      clickedOnImage={() => {}}
                      play={(play: boolean) => {
                        setPlay(play);
                        console.log("PLAY", play);
                      }}
                      pod={frame}
                      active={isActive}
                      playNarration={playNarration}
                      setPlayNarration={(value: boolean) =>
                        setPlayNarration(value)
                      }
                    ></Frame>
                  )}

                  {/* <pre>{JSON.stringify(frame, null, 3)}</pre> */}
                </SwiperSlide>
              ))}
            </Swiper>

            <IonGrid style={{ width: "100%" }} hidden={play}>
              <IonRow style={{ width: "100%" }}>
                <IonCol style={{ width: "100%" }}>
                  <IonButton
                    disabled={showBackButton}
                    expand="block"
                    fill="outline"
                    onClick={() => swiper.slidePrev()}
                  >
                    Back
                  </IonButton>
                </IonCol>
                {/* <IonCol style={{ width: "100%" }}>
                  <IonButton
                    expand="block"
                    fill="outline"
                    onClick={() => setPlayNarration(true)}
                  >
                    Repeat
                  </IonButton>
                </IonCol> */}
                <IonCol style={{ width: "100%" }}>
                  <IonButton
                    expand="block"
                    fill="outline"
                    onClick={() => swiper.slideNext()}
                  >
                    Next
                  </IonButton>
                </IonCol>
              </IonRow>
            </IonGrid>
            {narration && (
              <>
                {narration}
                <ReactAudioPlayer src={narration} controls />
              </>
            )}
          </div>
        )}
      </IonContent>
    </IonPage>
  );
};

const COURSES = print(gql`
  query CourseQuery($id: ID!) {
    getPod(id: $id) {
      id
      name
      pods {
        items {
          id
          name
          desc
          icon
          string
        }
      }
    }
  }
`);

export default TrainingPlayerCourse;
