import {
  IonCard,
  IonCardContent,
  IonCardHeader,
  IonCardTitle,
  IonContent,
  IonInput,
  IonItem,
  IonModal,
  IonPage,
  IonSegment,
  IonSegmentButton,
  IonSpinner,
} from "@ionic/react";
import Header from "../../../../components/Header";

import { print } from "graphql/language/printer";
import gql from "graphql-tag";
import axios from "axios";
import Frame from "../../../../components/Frame";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css/navigation";
import "swiper/css/pagination";
import "swiper/css/scrollbar";
import "swiper/css/effect-cube";
import "swiper/css/effect-coverflow";
import "swiper/css/effect-creative";
import "swiper/css";
import {
  EffectCards,
  EffectCreative,
  EffectCube,
  Navigation,
  Pagination,
  Thumbs,
} from "swiper";
import Phone from "../../../../components/Phone";
import React, { useEffect, useRef, useState } from "react";
import "./styles.css";
import { useAppDispatch, useAppSelector } from "../../../../redux/hooks";
import { useGetAppQuery, useLazyGetAppQuery } from "../../../../redux/appQuery";
import { useLocation } from "react-router";
import TrainingCoursesCourseFrameImagetext from "./Imagetext";
import { setFramePod } from "./TrainingCoursesCourseFrameSlice";

const TrainingCoursesCourseFrame: React.FC = () => {
  const hostname = useAppSelector((state) => state.app.location.hostname);
  const framePod = useAppSelector(
    (state) => state["training-courses-course-frame"].framePod
  );
  const location = useLocation();
  const [openTextEdit, setOpenTextEdit] = useState<boolean>();
  const [id, setID] = useState<string>(
    `model${location.pathname.replace(/\//g, "-")}`
  );
  // Refs
  const modal = useRef<HTMLIonModalElement>(null);
  const page = useRef(undefined);

  // Redux
  const dispatch = useAppDispatch();
  // Queries

  const [createPresignedPost, createPresignedPostResult] = useLazyGetAppQuery();
  // Modals
  const [presentingElement, setPresentingElement] = useState<
    HTMLElement | undefined
  >(undefined);

  // States
  const [swiper, setSwiper] = useState<any>();
  const [navIndex, setNavIndex] = useState<number>(1);

  const interfaces = [
    {
      name: "Imagetext",
      selected: true,
      id: 1,
    },
  ];

  // Queries
  const frameQuery = useGetAppQuery({
    query: GET_FRAME_QUERY,
    variables: {
      id: id,
    },
  });

  // Effects
  useEffect(() => {
    if (frameQuery.isSuccess)
      dispatch(setFramePod(frameQuery.data.getPod.join));
  }, [frameQuery]);

  useEffect(() => {
    if (swiper) {
      swiper.slideTo(navIndex - 1, 500);
    }
  }, [navIndex, swiper]);
  useEffect(() => {
    frameQuery.refetch();
    setPresentingElement(page.current);
  }, []);

  return (
    <IonPage ref={page}>
      <Header>Edit</Header>
      <IonContent fullscreen className="background">
        <IonSegment
          color="secondary"
          onIonChange={(el: any) => {
            setNavIndex(parseInt(el.target.value));
          }}
          value={navIndex.toString()}
        >
          {interfaces.map((interfaceInstance, interfaceId: number) => (
            <IonSegmentButton
              key={interfaceId}
              value={interfaceInstance.id.toString()}
            >
              {interfaceInstance.name}
            </IonSegmentButton>
          ))}
        </IonSegment>
        <div className="padding-small fullsize">
          <Swiper
            className="fullsize text-white padding-x-small"
            modules={[Navigation, Pagination, EffectCreative, Thumbs]}
            effect="slide"
            spaceBetween={0}
            slidesPerView={1}
            navigation={false}
            watchSlidesProgress
            pagination={{ clickable: true }}
            onSwiper={setSwiper}
            onSlideChange={(slide) => setNavIndex(slide.activeIndex + 1)}
            creativeEffect={{
              prev: {
                // will set `translateZ(-400px)` on previous slides
                translate: [0, 0, -400],
              },
              next: {
                // will set `translateX(100%)` on next slides
                translate: ["100%", 0, 0],
              },
            }}
          >
            <SwiperSlide className="padding-none" style={{ height: "90%" }}>
              {framePod ? (
                <TrainingCoursesCourseFrameImagetext
                  pageRef={page}
                  pod={framePod}
                  refetch={() => frameQuery.refetch()}
                ></TrainingCoursesCourseFrameImagetext>
              ) : (
                <IonSpinner></IonSpinner>
              )}
            </SwiperSlide>
          </Swiper>
        </div>
        <IonCard style={{ "--background": "rebeccapurple" }}>
          <IonCardHeader>
            <IonCardTitle>Title</IonCardTitle>
          </IonCardHeader>
          <IonCardContent>
            <IonItem style={{ "--background": "rebeccapurple" }}>
              <IonInput
                style={{
                  "--background": "darkslateblue",
                  padding: 0,
                  borderRadius: 5,
                }}
              ></IonInput>
            </IonItem>
          </IonCardContent>
        </IonCard>
      </IonContent>
    </IonPage>
  );
};

const GET_FRAME_QUERY = print(gql`
  query MyQuery($id: ID!) {
    getPod(id: $id) {
      id
      join {
        appID
        id
        desc
        icon
        name
        order
        url
        version
        selected
        schemaID
        string
        parent {
          id
          name
          desc
        }
      }
    }
  }
`);

export default TrainingCoursesCourseFrame;
