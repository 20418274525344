import {
  IonAlert,
  IonButton,
  IonButtons,
  IonCard,
  IonCardContent,
  IonCardHeader,
  IonCardTitle,
  IonContent,
  IonFab,
  IonFabButton,
  IonHeader,
  IonIcon,
  IonInput,
  IonItem,
  IonItemOption,
  IonItemOptions,
  IonItemSliding,
  IonLabel,
  IonList,
  IonLoading,
  IonModal,
  IonPage,
  IonSearchbar,
  IonSpinner,
  IonTextarea,
  IonTitle,
  IonToolbar,
} from "@ionic/react";
import { useEffect, useRef, useState } from "react";
import { useGetAppQuery, useSetAppMutation } from "../../redux/appQuery";
import { print } from "graphql/language/printer";
import gql from "graphql-tag";
import Header from "../../components/Header";
import { add, bookOutline, trashBinOutline } from "ionicons/icons";
import { useAppSelector } from "../../redux/hooks";
import * as changecase from "change-case";

const TrainingCourses: React.FC = () => {
  const modal = useRef<HTMLIonModalElement>(null);
  const [id, setID] = useState<string>(`model-training-courses`);
  const [addModal, setAddModal] = useState<boolean>(false);
  const listRef = useRef<any>();
  const page = useRef(undefined);
  const [confirmDelete, setConfirmDelete] = useState<number>();
  const [deletePodMutation, deletePodMutationResult] = useSetAppMutation();
  const [filter, setFilter] = useState<string | null | undefined>();
  const [presentingElement, setPresentingElement] = useState<
    HTMLElement | undefined
  >(undefined);
  const coursesQuery = useGetAppQuery({
    query: GET_COURSES_QUERY,
    variables: {
      id: id,
    },
  });

  useEffect(() => {
    setPresentingElement(page.current);
  }, []);
  useEffect(() => {
    if (deletePodMutationResult.status === "fulfilled") {
      coursesQuery.refetch();
    }
  }, [deletePodMutationResult]);
  const dismiss = () => {
    modal.current?.dismiss();
  };
  return (
    <IonPage ref={page}>
      <Header>Courses</Header>

      <IonContent fullscreen style={{ "--background": "darkslateblue" }}>
        <IonHeader collapse="condense">
          <IonToolbar style={{ "--background": "darkslateblue" }}>
            <IonTitle size="large">Courses</IonTitle>
          </IonToolbar>
        </IonHeader>
        <IonCard>
          <IonList ref={listRef}>
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                width: "100%",
              }}
            >
              {/* <div style={{ width: "50%", textAlign: "start" }}>
              <IonButton fill="clear" size="small">
                Sort
              </IonButton>
            </div> */}
              <div
                style={{
                  width: "100%",
                  backgroundColor: "darkslateblue",
                }}
              >
                <IonSearchbar
                  style={{ "--background": "slateblue" }}
                  animated={true}
                  placeholder="Filter"
                  onIonChange={(filterText) =>
                    setFilter(filterText.target.value)
                  }
                ></IonSearchbar>
              </div>
              {/* <div style={{ width: "50%", textAlign: "end" }}>
              <IonButton fill="clear" size="small">
                Arrange
              </IonButton>
            </div> */}
            </div>

            {coursesQuery.data ? (
              coursesQuery.data
                .getPod!?.pods.items.filter((el: any) =>
                  filter
                    ? el.name.toLowerCase().includes(filter.toLowerCase())
                    : el
                )
                .map((course: any) => (
                  <IonItemSliding key={course.id}>
                    <IonItem
                      style={{ "--background": "rebeccapurple" }}
                      routerLink={course.id
                        .replace("model-", "/")
                        .replace(/-/g, "/")}
                    >
                      <IonIcon
                        slot="start"
                        icon={bookOutline}
                        color="secondary"
                      ></IonIcon>
                      <IonLabel>
                        <h3>{course.name}</h3>
                      </IonLabel>
                    </IonItem>
                    <IonItemOptions side="start">
                      <IonItemOption
                        color="danger"
                        onClick={() => setConfirmDelete(course.id)}
                      >
                        <IonIcon icon={trashBinOutline}></IonIcon>
                        <IonLabel slot="bottom">Delete</IonLabel>
                      </IonItemOption>
                    </IonItemOptions>
                  </IonItemSliding>
                ))
            ) : (
              <div
                style={{
                  width: "100%",
                  height: "100%",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <IonSpinner></IonSpinner>
              </div>
            )}
          </IonList>
        </IonCard>
        <IonFab horizontal="end" vertical="bottom">
          <IonFabButton
            onClick={() => {
              setAddModal(true);
            }}
          >
            <IonIcon icon={add}></IonIcon>
          </IonFabButton>
        </IonFab>
        <IonModal
          isOpen={addModal}
          canDismiss={true}
          ref={modal}
          presentingElement={presentingElement}
          onWillDismiss={() => setAddModal(false)}
        >
          <AddCourse
            parentID={id}
            dismiss={() => dismiss()}
            refresh={() => coursesQuery.refetch()}
            order={coursesQuery.data!?.getPod!?.pods!?.items.length}
          ></AddCourse>
        </IonModal>
        <IonAlert
          isOpen={confirmDelete ? true : false}
          onDidDismiss={() => setConfirmDelete(undefined)}
          header="Confirmation"
          subHeader="This action is not reversible"
          message="Are you sure you wish to delete this course?"
          buttons={[
            { text: "Cancel", role: "cancel" },
            {
              text: "Delete",
              role: "confirm",
              handler: () => {
                deletePodMutation({
                  query: DELETE_POD_MUTATION,
                  variables: {
                    id: confirmDelete,
                  },
                });
              },
            },
          ]}
          onWillDismiss={() => {
            listRef.current.closeSlidingItems();
          }}
        />
      </IonContent>
    </IonPage>
  );
};

const AddCourse: React.FC<{
  parentID: String;
  dismiss: () => void;
  refresh: () => void;
  order: number;
}> = (props: any) => {
  const appID = useAppSelector((state) => state.app.process.REACT_APP_PREFIX);
  const [createPodMutation, createPodMutationResult] = useSetAppMutation();

  const [showLoading, setShowLoading] = useState<boolean>(false);
  const [pod, setPod] = useState<{
    appID: string;
    parentID: string;
    status: string;
    joinID: string;
    desc: string | null | undefined;
    id: string;
    name: string;
    schemaID: string;
    order: number;
  }>({
    appID: appID,
    name: "",
    parentID: props.parentID,
    status: "ACTIVE",
    joinID: "",
    desc: "",
    id: "",
    schemaID: "schema-training-courses-course",
    order: props.order + 1,
  });

  const createCourse = () => {
    const createPod = {
      ...pod,
      ...{
        id: `${props.parentID}-${changecase.snakeCase(pod.name)}`,
        joinID: `${props.parentID}-${changecase.snakeCase(pod.name)}`,
      },
    };
    console.log("Pod", createPod);
    createPodMutation({ query: CREATE_POD_MUTATION, variables: createPod });
    setShowLoading(true);
  };

  useEffect(() => {
    if (createPodMutationResult.status === "fulfilled") {
      setShowLoading(false);
      props.refresh();
      props.dismiss();
    }
  }, [createPodMutationResult]);

  return (
    <IonContent style={{ "--background": "slateblue" }}>
      <IonHeader>
        <IonToolbar style={{ "--background": "slateblue" }}>
          <IonButtons>
            <IonButton onClick={() => props.dismiss()} color="light">
              Close
            </IonButton>
          </IonButtons>
        </IonToolbar>
      </IonHeader>
      <IonCard style={{ "--background": "darkslateblue" }}>
        <IonCardHeader>
          <IonCardTitle>Create Course</IonCardTitle>
        </IonCardHeader>
        <IonCardContent>
          <IonList>
            <IonItem>
              <IonLabel position="stacked">Course Name</IonLabel>
              <IonInput
                value={pod.name}
                onIonChange={(input: any) =>
                  setPod({ ...pod, ...{ name: input.target.value } })
                }
              ></IonInput>
            </IonItem>
            <IonItem>
              <IonLabel position="stacked">Course Description</IonLabel>
              <IonTextarea
                value={pod.desc}
                onIonChange={(input) =>
                  setPod({ ...pod, ...{ desc: input.target.value } })
                }
              ></IonTextarea>
            </IonItem>
          </IonList>
          <IonButton
            expand="block"
            fill="outline"
            onClick={() => createCourse()}
            color="light"
          >
            Create
          </IonButton>
        </IonCardContent>
      </IonCard>
      <IonLoading
        isOpen={showLoading}
        onDidDismiss={() => setShowLoading(false)}
        message={"Creating Course..."}
      />
    </IonContent>
  );
};

const CREATE_POD_MUTATION = print(gql`
  mutation CREATE_POD_MUTATION(
    $appID: ID!
    $id: ID!
    $desc: String!
    $joinID: ID!
    $name: String
    $order: Int!
    $parentID: ID!
    $schemaID: ID!
  ) {
    createPod(
      input: {
        appID: $appID
        id: $id
        desc: $desc
        joinID: $joinID
        name: $name
        order: $order
        parentID: $parentID
        schemaID: $schemaID
        status: ACTIVE
      }
    ) {
      id
    }
  }
`);

const GET_COURSES_QUERY = print(gql`
  query GET_TRAINING_COURSES($id: ID!) {
    getPod(id: $id) {
      id
      pods(sortDirection: DESC, limit: 1000) {
        items {
          id
          name
        }
      }
    }
  }
`);

const DELETE_POD_MUTATION = print(gql`
  mutation DELETE_POD($id: ID!) {
    deletePod(input: { id: $id }) {
      id
    }
  }
`);

export default TrainingCourses;
