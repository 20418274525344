import {
  IonAlert,
  IonAvatar,
  IonButton,
  IonButtons,
  IonCard,
  IonCardContent,
  IonCardHeader,
  IonCardTitle,
  IonContent,
  IonFab,
  IonFabButton,
  IonHeader,
  IonIcon,
  IonInput,
  IonItem,
  IonItemOption,
  IonItemOptions,
  IonItemSliding,
  IonLabel,
  IonList,
  IonLoading,
  IonMenuButton,
  IonModal,
  IonNote,
  IonPage,
  IonRefresher,
  IonRefresherContent,
  IonReorder,
  IonReorderGroup,
  IonTextarea,
  IonTitle,
  IonToolbar,
  ItemReorderEventDetail,
  RefresherEventDetail,
} from "@ionic/react";
import { useLocation, useParams } from "react-router";
import { useGetAppQuery, useSetAppMutation } from "../../../redux/appQuery";
import { useAppSelector } from "../../../redux/hooks";
import { useGetCoursesQuery } from "./../trainingCoursesSlice";
import { print } from "graphql/language/printer";
import gql from "graphql-tag";
import Header from "../../../components/Header";
import { useEffect, useRef, useState } from "react";
import { add, trashBinOutline } from "ionicons/icons";
import * as changecase from "change-case";
import PodImage from "../../../components/PodImage";
const TrainingCoursesCourse: React.FC = () => {
  const { course } = useParams<{ course: string }>();
  const location = useLocation();
  const modal = useRef<HTMLIonModalElement>(null);
  const [addModal, setAddModal] = useState<boolean>(false);
  const [presentingElement, setPresentingElement] = useState<
    HTMLElement | undefined
  >(undefined);
  const page = useRef();

  const [id, setID] = useState<string>(
    `model${location.pathname.replace(/\//g, "-")}`
  );
  const pathname = useAppSelector((state) => state.app.location.pathname);
  const [confirmDelete, setConfirmDelete] = useState<number>();
  const [deletePodMutation, deletePodMutationResult] = useSetAppMutation();
  const listRef = useRef<any>();
  const listReorderRef = useRef<any>();
  const [enableReorder, setEnableReorder] = useState<boolean>(false);
  const framesQuery = useGetAppQuery({
    query: GET_COURSES_QUERY,
    variables: {
      id: id,
    },
  });
  useEffect(() => {
    setPresentingElement(page.current);
    framesQuery.refetch();
  }, []);
  useEffect(() => {
    if (deletePodMutationResult.status === "fulfilled") {
      framesQuery.refetch();
    }
  }, [deletePodMutationResult]);
  const dismiss = () => {
    modal.current?.dismiss();
  };

  const handleRefresh = (event: CustomEvent<RefresherEventDetail>) => {
    framesQuery.refetch().then((res) => {
      console.log("Refreshed");
      event.detail.complete();
    });
  };

  const handleReorder = (ev: any) => {
    console.log("EV", ev.detail);
    if (ev.detail.from > ev.detail.to) {
      console.log("Up");
    } else {
      console.log("Down");
      // for (var i = ev.detail.from; i < ev.detail.to; i++) {
      //   console.log(
      //     framesQuery.data.getPod.pods.items[i].id,
      //     i,
      //     framesQuery.data.getPod.pods.items[i].order
      //   );
      // }
      console.log(
        "Array",
        framesQuery.data.getPod.pods.items.slice(
          ev.detail.from,
          ev.detail.to + 1
        )
      );
      console.log(
        framesQuery.data.getPod.pods.items[ev.detail.from].id,
        ev.detail.to
      );
    }

    //console.log(framesQuery.data.getPod.pods.items);

    // framesQuery.data.getPod.pods.items.map((frame: any, index: number) => {
    //   console.log("frame", index, frame.order);
    // });
    setEnableReorder(!enableReorder);
    ev.detail.complete();
  };
  return (
    <IonPage ref={page}>
      <Header>Course</Header>

      {/* <IonHeader>
        <IonToolbar style={{ "--background": "darkslateblue" }}>
          <IonButtons slot="start">
            <IonMenuButton />
          </IonButtons>
          <IonTitle>{course}</IonTitle>
        </IonToolbar>
      </IonHeader> */}

      <IonContent fullscreen style={{ "--background": "darkslateblue" }}>
        <div
          style={{
            textAlign: "center",
            fontSize: "x-small",
            width: "100%",
            backgroundColor: "rebeccapurple",
          }}
        >
          <IonRefresher slot="fixed" onIonRefresh={handleRefresh}>
            <IonRefresherContent
              style={{ backgroundColor: "rebeccapurple" }}
            ></IonRefresherContent>
          </IonRefresher>
          &#x25BC; &nbsp; PULL TO REFRESH &nbsp; &#x25BC;
        </div>

        <IonHeader collapse="condense">
          <IonToolbar style={{ "--background": "darkslateblue" }}>
            <IonTitle size="large">
              {framesQuery.data && framesQuery.data.getPod.name}
            </IonTitle>
          </IonToolbar>
        </IonHeader>
        <IonCard style={{ "--background": "darkslateblue" }}>
          <div
            hidden
            style={{
              display: "flex",
              justifyContent: "space-between",
              flexDirection: "row",
            }}
          >
            <div></div>
            <div></div>
            <div style={{ justifyContent: "end" }}>
              <IonButton
                fill={enableReorder ? "solid" : "outline"}
                size="small"
                color="light"
                onClick={() => setEnableReorder(!enableReorder)}
              >
                Arrange
              </IonButton>
            </div>
          </div>
          <IonList ref={listRef}>
            <IonReorderGroup
              ref={listReorderRef}
              disabled={!enableReorder}
              onIonItemReorder={handleReorder}
            >
              {framesQuery.data &&
                framesQuery.data.getPod.pods.items.map((frame: any) => (
                  <IonItemSliding key={frame.id}>
                    <IonItem
                      id={frame.id}
                      style={{ "--background": "rebeccapurple" }}
                      key={frame.id}
                      routerLink={frame.id
                        .replace("model-", "/")
                        .replace(/-/g, "/")}
                    >
                      {/* <IonNote slot="start" color="secondary">
                    {frame.order}
                  </IonNote> */}
                      <IonAvatar slot="start">
                        {frame.icon ? (
                          <PodImage
                            id={`${frame.id}-${frame.icon}`}
                            width={64}
                            height={64}
                            style={{
                              borderRadius: 5,
                              flexShrink: 0,
                              width: "auto",
                              height: "auto",
                              minWidth: "100%",
                              minHeight: "100%",
                            }}
                          ></PodImage>
                        ) : (
                          <div
                            style={{
                              width: 32,
                              height: 32,
                              backgroundColor: "slateblue",
                              borderRadius: "50%",
                            }}
                          ></div>
                        )}
                      </IonAvatar>
                      <IonLabel>
                        <h3>{frame.name}</h3>
                        <p>{frame.desc}</p>
                      </IonLabel>
                      <IonReorder slot="end"></IonReorder>
                    </IonItem>
                    <IonItemOptions side="start">
                      <IonItemOption
                        color="danger"
                        onClick={() => setConfirmDelete(frame.id)}
                      >
                        <IonIcon icon={trashBinOutline}></IonIcon>
                        <IonLabel slot="bottom">Delete</IonLabel>
                      </IonItemOption>
                    </IonItemOptions>
                  </IonItemSliding>
                ))}
            </IonReorderGroup>
          </IonList>
        </IonCard>
        <IonFab horizontal="end" vertical="bottom" slot="fixed">
          <IonFabButton
            onClick={() => {
              setAddModal(true);
            }}
          >
            <IonIcon icon={add}></IonIcon>
          </IonFabButton>
        </IonFab>
        <IonModal
          isOpen={addModal}
          canDismiss={true}
          ref={modal}
          presentingElement={presentingElement}
          onWillDismiss={() => setAddModal(false)}
        >
          <AddFrame
            parentID={id}
            dismiss={() => dismiss()}
            refresh={() => framesQuery.refetch()}
            order={framesQuery.data!?.getPod!?.pods!?.items.length}
          ></AddFrame>
        </IonModal>
        <IonAlert
          isOpen={confirmDelete ? true : false}
          onDidDismiss={() => setConfirmDelete(undefined)}
          header="Confirmation"
          subHeader="This action is not reversible"
          message="Are you sure you wish to delete this frame?"
          buttons={[
            { text: "Cancel", role: "cancel" },
            {
              text: "Delete",
              role: "confirm",
              handler: () => {
                deletePodMutation({
                  query: DELETE_POD_MUTATION,
                  variables: {
                    id: confirmDelete,
                  },
                });
              },
            },
          ]}
          onWillDismiss={() => {
            listRef.current.closeSlidingItems();
          }}
        />
      </IonContent>
    </IonPage>
  );
};

const AddFrame: React.FC<{
  parentID: String;
  dismiss: () => void;
  refresh: () => void;
  order: number;
}> = (props: any) => {
  const appID = useAppSelector((state) => state.app.process.REACT_APP_PREFIX);
  const [createPodMutation, createPodMutationResult] = useSetAppMutation();
  const [showLoading, setShowLoading] = useState<boolean>(false);
  const [pod, setPod] = useState<{
    appID: string;
    parentID: string;
    status: string;
    joinID: string;
    desc: string | null | undefined;
    id: string;
    name: string;
    schemaID: string;
    order: number;
  }>({
    appID: appID,
    name: "",
    parentID: props.parentID,
    status: "ACTIVE",
    joinID: "",
    desc: "",
    id: "",
    schemaID: "schema-training-courses-course",
    order: props.order + 1,
  });
  useEffect(() => {
    if (createPodMutationResult.status === "fulfilled") {
      setShowLoading(false);
      props.refresh();
      props.dismiss();
    }
  }, [createPodMutationResult]);
  const createFrame = () => {
    const createPod = {
      ...pod,
      ...{
        id: `${props.parentID}-${changecase.snakeCase(pod.name)}`,
        joinID: `${props.parentID}-${changecase.snakeCase(pod.name)}`,
      },
    };
    console.log("Pod", createPod);
    createPodMutation({ query: CREATE_POD_MUTATION, variables: createPod });
    setShowLoading(true);
  };
  return (
    <IonContent style={{ "--background": "slateblue" }}>
      <IonHeader>
        <IonToolbar style={{ "--background": "slateblue" }}>
          <IonButtons>
            <IonButton onClick={() => props.dismiss()}>Close</IonButton>
          </IonButtons>
        </IonToolbar>
      </IonHeader>
      <IonCard style={{ "--background": "darkslateblue" }}>
        <IonCardHeader>
          <IonCardTitle>Create Frame</IonCardTitle>
        </IonCardHeader>
        <IonCardContent>
          <IonList>
            <IonItem>
              <IonLabel position="stacked">Frame Name</IonLabel>
              <IonInput
                value={pod.name}
                onIonChange={(input: any) =>
                  setPod({ ...pod, ...{ name: input.target.value } })
                }
              ></IonInput>
            </IonItem>
            <IonItem>
              <IonLabel position="stacked">Frame Description</IonLabel>
              <IonTextarea
                value={pod.desc}
                onIonChange={(input) =>
                  setPod({ ...pod, ...{ desc: input.target.value } })
                }
              ></IonTextarea>
            </IonItem>
          </IonList>
          <IonButton
            expand="block"
            fill="outline"
            onClick={() => createFrame()}
          >
            Create
          </IonButton>
        </IonCardContent>
      </IonCard>
      <IonLoading
        isOpen={showLoading}
        onDidDismiss={() => setShowLoading(false)}
        message={"Creating Frame..."}
      />
    </IonContent>
  );
};

const GET_COURSES_QUERY = print(gql`
  query GET_TRAINING_COURSES($id: ID!) {
    getPod(id: $id) {
      id
      name
      desc
      pods {
        items {
          id
          name
          icon
          order
          desc
        }
      }
    }
  }
`);

const CREATE_POD_MUTATION = print(gql`
  mutation CREATE_POD_MUTATION(
    $appID: ID!
    $id: ID!
    $desc: String!
    $joinID: ID!
    $name: String
    $order: Int!
    $parentID: ID!
    $schemaID: ID!
  ) {
    createPod(
      input: {
        appID: $appID
        id: $id
        desc: $desc
        joinID: $joinID
        name: $name
        order: $order
        parentID: $parentID
        schemaID: $schemaID
        status: ACTIVE
      }
    ) {
      id
    }
  }
`);

const DELETE_POD_MUTATION = print(gql`
  mutation DELETE_POD($id: ID!) {
    deletePod(input: { id: $id }) {
      id
    }
  }
`);

export default TrainingCoursesCourse;
