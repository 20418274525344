import {
  IonCard,
  IonCol,
  IonContent,
  IonGrid,
  IonHeader,
  IonItem,
  IonList,
  IonPage,
  IonRow,
  IonTitle,
  IonToolbar,
} from "@ionic/react";
import { useRef, useState } from "react";
import Header from "../../../components/Header";

import { print } from "graphql/language/printer";
import gql from "graphql-tag";
import { useGetAppQuery } from "../../../redux/appQuery";
import { useLocation } from "react-router";
import PodImage from "../../../components/PodImage";
const TrainingPlayer: React.FC = () => {
  const location = useLocation();
  const page = useRef();
  const [id, setID] = useState<string>(
    `model${location.pathname.replace(/\//g, "-")}`
  );

  const coursesQuery = useGetAppQuery({
    query: COURSES,
    variables: {
      id: id,
    },
  });
  return (
    <IonPage ref={page}>
      <Header>Player</Header>

      <IonContent fullscreen style={{ "--background": "darkslateblue" }}>
        <IonHeader collapse="condense">
          <IonToolbar style={{ "--background": "darkslateblue" }}>
            <IonTitle size="large">Player</IonTitle>
          </IonToolbar>
        </IonHeader>
        <IonGrid>
          <IonRow>
            {coursesQuery.data &&
              coursesQuery.data.getPod.pods.items.map((course: any) => (
                <IonCol size="6" key={course.id}>
                  <IonCard
                    routerLink={`/training/player/course/${course.id}`}
                    style={{
                      "--background": "rebeccapurple",
                      color: "white",
                      textAlign: "center",
                      padding: 10,
                    }}
                  >
                    {course.pods.items[0].icon ? (
                      <PodImage
                        id={`${course.pods.items[0].id}-${course.pods.items[0].icon}`}
                        width={128}
                        height={128}
                        style={{
                          borderRadius: 10,
                          flexShrink: 0,
                          width: "auto",
                          height: "auto",
                          minWidth: "100%",
                          minHeight: "100%",
                        }}
                      ></PodImage>
                    ) : (
                      <div>Placeholder</div>
                    )}
                    {course.name}
                  </IonCard>
                </IonCol>
              ))}
          </IonRow>
        </IonGrid>
        {/* <IonList>
          {coursesQuery.data &&
            coursesQuery.data.getPod.pods.items.map((course: any) => (
              <IonItem
                key={course.id}
                routerLink={`/training/player/course/${course.id}`}
              >
                {course.name}
              </IonItem>
            ))}
        </IonList> */}
      </IonContent>
    </IonPage>
  );
};

const COURSES = print(gql`
  query COURSES_QUERY {
    getPod(id: "model-training-courses") {
      id
      pods {
        items {
          id
          name
          pods(limit: 1) {
            items {
              id
              icon
            }
          }
        }
      }
    }
  }
`);

export default TrainingPlayer;
